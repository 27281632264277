.join {
  display: flex;
  gap: 10rem;
  padding: 0 2rem;
}
.join-left > hr {
  border: 2px solid var(--orange);
  border-radius: 15%;
  width: 10.5rem;
  margin: 10px 0;
}

.join-left > div:nth-of-type(1) {
  display: flex;
  gap: 1rem;
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
}
.join-left > div:nth-of-type(2) {
  display: flex;
  gap: 1rem;
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
}
.join-right {
  display: flex;
  align-items: flex-end;
}
.join-right > form {
  display: flex;
  gap: 2rem;
  align-items: center;
  background: var(--gray);
  padding: 1rem 2rem;
}
.join-right > form > input {
  padding: 1px 2px;
  background: transparent;
  color: var(--caloryCard);
  outline: none;
  font-weight: bold;
  padding: 1rem 0.1rem;
  border: none;
}
.join-right > form > button {
  background: var(--orange);
  color: white;
}
@media screen and (max-width: 768px) {
  .join {
    flex-direction: column;
    justify-content: center;
    align-self: center;
    gap: 1rem;
  }
  .join-left {
    font-size: large;
    flex-direction: column;
  }
  .join-right {
    padding: 2rem;
  }
}

@media screen and (max-width: 450px) {
  .join-left {
    transform: scale(0.7);
  }
  .join-right {
    transform: scale(0.7);
  }
}
