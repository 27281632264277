.reason-desc {
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  gap: 1rem;
  color: white;
  font-size: 1rem;
  align-items: center;
  /* width: fit-content; */
}
.reason-desc > img {
  width: 2rem;
}
