.header {
  display: flex;
  justify-content: space-between;
}
.logo {
  width: 10rem;
  height: 3rem;
}
.header-menu {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  list-style: none;
  color: white;
}
.header-menu > a {
  gap: 2rem;
  list-style: none;
  color: white;
}
.header-menu > a:hover {
  cursor: pointer;
  color: var(--orange);
}

/* @media screen and (max-width: 768px) {
  .header-menu {
    display: none;
  }
} */
@media screen and (max-width: 768px) {
  .header > :nth-child(2) {
    z-index: 99;
  }
  .header-menu {
    position: fixed;
    flex-direction: column;
    background: #3c3f45ed;
    padding-left: 0;
    padding: 2rem 0rem;
    transform: translate(-2rem, -3rem);

    text-align: center;
    width: 100%;
  }
}
