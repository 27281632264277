.footer-container > hr {
  margin: 0.5rem 0;
  color: white;
}
.footer {
  display: flex;
  flex-direction: column;
  /* gap: 4rem; */
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem 2rem;
  height: 20rem;
}
.social-links {
  display: flex;
  gap: 4rem;
  justify-content: space-between;
}
.social-links > img {
  width: 2rem;
  height: 2rem;
}
.footer > img {
  width: 10rem;
}
