.testimonial {
  display: flex;
  gap: 1rem;
  padding: 0 2rem;
}
.testimonial-left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 50%;
}
.testimonial-left > :nth-child(1) {
  color: var(--orange);
  font-weight: bold;
  text-transform: uppercase;
}

.testimonial-left > :nth-child(2) {
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: bold;
}
.testimonial-left > :nth-child(3) {
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
}
.testimonial-left > :nth-child(4) {
  color: white;
  letter-spacing: 2px;
  line-height: 40px;
  text-transform: none;
  text-align: justify;
}
.testimonial-right {
  position: relative;
  width: 50%;
}
.testimonial-right > div:nth-of-type(1) {
  position: absolute;
  border: 2px solid var(--orange);
  right: 9rem;
  top: 0.9rem;
  width: 17rem;
  height: 20rem;
}
.testimonial-right > div:nth-of-type(2) {
  position: absolute;
  background: var(--planCard);
  right: 7rem;
  top: 4rem;
  width: 17rem;
  height: 19rem;
}

.testimonial-right > img {
  position: absolute;
  object-fit: cover;
  right: 8rem;
  top: 2rem;
  width: 17rem;
  height: 20rem;
}
.testimonial-right > div:nth-of-type(3) {
  position: absolute;
  display: flex;
  gap: 1rem;
  left: 3rem;
  bottom: 1rem;
}
.testimonial-right > div:nth-of-type(3) > img {
  width: 1.5rem;
}

@media screen and (max-width: 900px) {
  .testimonial {
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
  }
  .testimonial-left {
    width: 100%;
  }
  .testimonial-left > :nth-child(2),
  .testimonial-left > :nth-child(3) {
    font-size: xx-large;
  }
  .testimonial-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 100%;
  }
  .testimonial-right > div {
    position: relative;
    display: none;
  }
  .testimonial-right > img {
    position: relative;
    align-self: center;
    top: 0;
    right: 0;
  }
  .testimonial-right > div:nth-of-type(3) {
    position: relative;
    bottom: 0;
    left: 0;
  }
}
