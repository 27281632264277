.price-card {
  width: fit-content;
  width: 15rem;
  background-color: var(--caloryCard);
  display: flex;
  flex-direction: column;

  padding: 1.5rem;
  gap: 2rem;
  color: white;
}
.price-card > ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.price-card > ul > div {
  display: flex;
  gap: 1rem;
}
.price-card > ul > div > img {
  width: 1rem;
  height: 1rem;
}
.price-card > svg {
  width: 2rem;
  height: auto;
  fill: var(--orange);
}

.price-card > :nth-child(2) {
  font-weight: bold;
}
.price-card > :nth-child(3) {
  font-size: 3rem;
  font-weight: bold;
}
.price-card > :nth-child(5) {
  display: flex;
  gap: 1rem;
  font-size: 0.8rem;
}
.price-card > :nth-child(5) > img {
  width: 0.8rem;
  height: 0.8rem;
}
