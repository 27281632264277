.reasons {
  display: flex;
  gap: 2rem;

  padding: 2rem;
}
.reasons-left {
  width: 50%;
  display: flex;
  gap: 1rem;
}
.reasons-left > img {
  width: 50%;
  height: 37.5rem;
}
.reasons-left > div {
  display: flex;
  flex-direction: column;
  height: 37.5rem;
  gap: 1rem;
}
.reasons-right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
}
.reasons-right > span:nth-child(1) {
  color: var(--orange);
  font-weight: bold;
  text-transform: uppercase;
}
.reasons-right > div:nth-child(2) {
  color: white;
  font-size: 3rem;
  font-weight: bold;
}
.reasons-right > ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reasons-right > span:nth-child(4) {
  text-transform: uppercase;
  color: var(--gray);
}
.reasons-right > div:nth-child(5) {
  display: flex;
  gap: 1rem;
}
.reasons-right > div:nth-child(5) > img {
  width: 40px;
  height: 28px;
}
@media screen and (max-width: 1100px) {
  .reasons {
    flex-direction: column;
    align-self: center;
  }
  .reasons-left {
    width: 100%;
  }
  /* .reasons-right {

  } */
}
@media screen and (max-width: 600px) {
  .reasons-left {
    flex-direction: column;
  }
  .reasons-left > img {
    width: 100%;
  }
  .reasons-left > div > img {
    overflow: hidden;
  }
}
